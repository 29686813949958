module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"openGraph":{"title":"Liva Insurance Oman","type":"website","locale":"en_US","description":"Get motor insurance online in just a few clicks and save 10%! Enjoy a simple, secure, and hassle-free experience with comprehensive coverage tailored to your needs. Get your quote today!","images":[{"url":"https://optimusapp.lemonhq.io/images/livaLogo.png","alt":"Liva"}],"site_name":"Liva"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
